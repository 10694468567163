<template>
  <div>
    <CModal
      :show.sync="showModal"
      :close-on-backdrop="false"
      :centered="true"
      title="Modal title 2"
      size="lg"
      color="dark"
    > 
      <label>UUID</label>
      <CInput
        type="text"
        :value="form.uuid"
        readonly        
        class="validate_number"
      />
      <label class="required-field">Length (in months)</label>
      <CInput
        type="number"
        v-model.trim="form.length"
        min="1"
        oninput="this.value = Math.abs(this.value)"
        placeholder="Enter your length"
        class="validate_number"
      />
      <span v-if="$v.form.length.$error" class="text-danger">Length is required</span>
      <span v-if="lengthValidate" style="color:red;">
        {{
        lengthValidate
        }}
      </span>
      <!-- <CInput
        type="number"
        v-model="form.price"
        label="Price"
        min="1"
        oninput="this.value = Math.abs(this.value)"
        placeholder="Enter your price"
      />-->
      <template #header>
        <h6 class="modal-title">Contract Lengths</h6>
        <CButtonClose @click="onCloseModal()" class="text-white" />
      </template>
      <template #footer>
        <CButton @click.prevent="saveModal" color="success">Save</CButton>
        <CButton @click="onCloseModal()" color="danger">Cancel</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import {
  postContractLength,
  getContractLength,
  putContractLength
} from "@/api/contractLengths";
import { required } from "vuelidate/lib/validators";

export default {
  name: "UserEditForm",
  props: {
    id: {
      type: Number,
      default: 0
    },
    modalShow: {
      type: Boolean,
      default: false
    },
    onHideModal: {
      type: Function
    },
  },
  created() {
    this.showModal = this.$props.modalShow;
    if (this.$props.id) {
      getContractLength(this.$props.id)
        .then(res => {
          this.form = res.data.data;
        })
        .catch(error => {
          console.log(error, "error");
        });
    }
  },
  mounted() {
    document
      .querySelector(".validate_number")
      .addEventListener("keypress", function(evt) {
        if (
          (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
          evt.which > 57
        ) {
          evt.preventDefault();
        }
      });
  },
  data: () => {
    return {
      showModal: false,
      form: {
        length: ""
        // price: ""
      },
      errors: null,
      lengthValidate: null
    };
  },
  methods: {
    changeModal() {
      if (!this.showModal) {
        this.onCloseModal();
      }
    },
    onCloseModal() {
      this.showModal = false;
      this.$props.onHideModal();
    },
    saveModal() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      if (this.$props.id) {
        putContractLength(this.$props.id, this.form)
          .then(res => {
            this.$emit("saveContractLength", res.data.data, this.$props.id);
            this.$notify({
              group: "notify",
              type: "success",
              text: res.data.message
            });
            this.onCloseModal();
          })
          .catch(error => {
            console.log(error.response, "=");
            if (error.response.data.data.length) {
              this.lengthValidate = error.response.data.data.length[0];
            }

            console.log(error, "error");
          });
      } else {
        postContractLength(this.form)
          .then(res => {
            console.log(res);
            this.$emit("saveContractLength", res.data.data);
            this.$notify({
              group: "notify",
              type: "success",
              text: res.data.message
            });
            this.onCloseModal();
          })
          .catch(error => {
            console.log(error, "error");
            if (error.response.data.data.length) {
              this.lengthValidate = error.response.data.data.length[0];
            }
          });
      }
    }
  },
  validations: {
    form: {
      length: {
        required
      }
    }
  }
};
</script>
